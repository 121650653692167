import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'

import { ROLE_ADMIN, ROLE_RECOGNIZER, ROLE_ASSESSOR } from '@/components/utils.js'

const Login = () => import('@/pages/Login.vue')
const MainData = () => import('@/pages/MainData.vue')
const Clinics = () => import('@/pages/Clinics.vue')
const Drugs = () => import('@/pages/Drugs.vue')
const Departments = () => import('@/pages/Departments.vue')
const Diagnoses = () => import('@/pages/Diagnoses.vue')
const Managers = () => import('@/pages/Managers.vue')
const Profile = () => import('@/pages/Profile.vue')
const Settings = () => import('@/pages/Settings.vue')
const Statistics = () => import('@/pages/Statistics.vue')

Vue.use(Router)

const redirect = () => {
  switch(store.user.role) {
    case ROLE_ADMIN:
      return { name: 'statistics' } 
    case ROLE_RECOGNIZER:
      return { name: 'fio' }
    case ROLE_ASSESSOR:
      return { name: 'control' }
  }

  return { name: 'login' }
}

const acl = (to, from, next) => {
  if (!store.user.id) {
    next({ name: 'login', query: { redirect: to.fullPath } })
  } else if (to.meta.roles && to.meta.roles.indexOf(store.user.role) == -1) {
    next(false)
  } else {
    next()
  }
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      beforeEnter: acl,
      redirect: redirect
    }, {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {layout: 'no-menu'}
    }, {
      path: '/profile',
      name: 'profile',
      component: Profile,
      beforeEnter: acl

    }, {
      path: '/fio',
      name: 'fios',
      component: MainData,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN, ROLE_RECOGNIZER], nav: true},
      props: () => ({ mode: 'fio' })
    }, {
      path: '/fio/:recId',
      name: 'fio',
      component: MainData,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN, ROLE_RECOGNIZER]},
      props: (route) => ({ recId: parseInt(route.params.recId), mode: 'fio' })
    }, {
      path: '/prescription',
      name: 'prescriptions',
      component: MainData,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN, ROLE_RECOGNIZER], nav: true},
      props: () => ({ mode: 'prescription' })
    }, {
      path: '/prescription/:recId',
      name: 'prescription',
      component: MainData,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN, ROLE_RECOGNIZER]},
      props: (route) => ({ recId: parseInt(route.params.recId), mode: 'prescription' })
    }, {
      path: '/control',
      name: 'controls',
      component: MainData,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN, ROLE_ASSESSOR], nav: true},
      props: () => ({ mode: 'control' })
    }, {
      path: '/control/:recId',
      name: 'control',
      component: MainData,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN, ROLE_RECOGNIZER]},
      props: (route) => ({ recId: parseInt(route.params.recId), mode: 'control' })
    }, {
      path: '/guide/clinics',
      name: 'clinics',
      component: Clinics,
      beforeEnter: acl,
      props: { recId: null }
    }, {
      path: '/guide/clinic/:recId',
      name: 'clinic',
      component: Clinics,
      beforeEnter: acl,
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/guide/drugs',
      name: 'drugs',
      component: Drugs,
      beforeEnter: acl,
      props: { recId: null }
    }, {
      path: '/guide/drug/:recId',
      name: 'drug',
      component: Drugs,
      beforeEnter: acl,
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/guide/departments',
      name: 'departments',
      component: Departments,
      beforeEnter: acl,
      props: { recId: null }
    }, {
      path: '/guide/department/:recId',
      name: 'department',
      component: Departments,
      beforeEnter: acl,
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/guide/diagnoses',
      name: 'diagnoses',
      component: Diagnoses,
      beforeEnter: acl,
      props: { recId: null }
    }, {
      path: '/guide/diagnose/:recId',
      name: 'diagnose',
      component: Diagnoses,
      beforeEnter: acl,
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/managers',
      name: 'managers',
      component: Managers,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN], nav: true},
      props: { recId: null }
    }, {
      path: '/manager/:recId',
      name: 'manager',
      component: Managers,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN]},
      props: (route) => ({ recId: parseInt(route.params.recId) })
    }, {
      path: '/statistics',
      name: 'statistics',
      component: Statistics,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN], nav: true},
      props: { recId: null }
    }, {
      path: '/statistic/:prescriptionId/fio',
      name: 'statistic_fio',
      component: Statistics,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN]},
      props: (route) => ({ prescriptionId: parseInt(route.params.prescriptionId), mode: 'fio' })
    }, {
      path: '/statistic/:prescriptionId/prescription',
      name: 'statistic_prescription',
      component: Statistics,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN]},
      props: (route) => ({ prescriptionId: parseInt(route.params.prescriptionId), mode: 'prescription' })
    }, {
      path: '/statistic/:prescriptionId/raw',
      name: 'statistic_raw',
      component: Statistics,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN]},
      props: (route) => ({ prescriptionId: parseInt(route.params.prescriptionId), mode: 'raw' })
    }, {
      path: '/settings',
      name: 'settings',
      component: Settings,
      beforeEnter: acl,
      meta: {roles: [ROLE_ADMIN]},
    },
    { path: '*', redirect: redirect }
  ]
})
