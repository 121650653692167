import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from '@/router'
import axios from 'axios'
import {setGlobalDateI18n} from 'fecha';
import '@/components'
import '@/layouts'
import '@/plugins'
import store from './store.js'
import locale from 'element-ui/lib/locale/lang/ru-RU.js'

const $axios = axios.create({
  baseURL: '/admin',
  timeout: 10 * 60 * 1000
})

$axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    store.user.logout()
  }
  return Promise.reject(error)
})

store.user.axios = $axios
Vue.prototype.$axios = $axios

setGlobalDateI18n({
  dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
  // amPm: ['am', 'pm'],
  // DoFn: function (D) {
    // return D + [ 'th', 'st', 'nd', 'rd' ][ D % 10 > 3 ? 0 : (D - D % 10 !== 10) * D % 10 ];
  // }
})

Vue.use(ElementUI, { locale })
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  data: { store },
  render: h => h(App),
}).$mount('#app')
