import MedWait from './med_wait.vue'

export default {
  install: function (Vue) {
    const MedWaitComponent = Vue.extend(MedWait)
    Vue.prototype.$wait = new MedWaitComponent({
      el: document.createElement('div')
    })
  }
}
