<template>
  <div class="mcb-detail-popup">
    <div class="mcb-detail-popup-wrap">
      <div class="mcb-detail-popup-header">
        <span>{{title}}</span>
        <mcb-btn-close @click="$emit('esc')" />
      </div>

      <div class="mcb-detail-popup-content">
        <slot />
      </div>
      <div class="mcb-detail-popup-footer">
        <div><slot name="footer" /></div>
        <div class="buttons-footer">
          <el-button type="danger" @click="$emit('delete')" v-if="canDelete && $listeners.delete">Удалить</el-button>
          <el-button type="primary" @click="$emit('save')" v-if="$listeners.save">{{saveName}}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },

    saveName: {
      type: String,
      default: 'OK'
    },

    canDelete: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.mcb-detail-popup {
  background-color: rgba(0,0,0,.2);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center; 

  .mcb-detail-popup-wrap {
    min-width: 200px;
    max-height: calc(100vh - @header-height - 4px);

    background-color: @white-color;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .mcb-detail-popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 18px 20px;
      border-bottom: 1px solid #ebeef5;
      box-sizing: border-box;  

      .mcb-btn-close {
        margin-left: 10px;
      }
    }

    .mcb-detail-popup-content {
      padding: 20px;
      flex: 1 1 auto;
      // overflow: hidden;
      overflow-x: hidden;
    }

    .mcb-detail-popup-footer {
      padding: 20px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .buttons-footer {
        padding-right: 10px;
      }
    }
  }
}
</style>
