<template>
  <div class="med-wait" tabindex="0" aria-label="Loading" v-show="showed">
    <svg viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#000" width="60" height="240">
      <circle cx="15" cy="15" r="15">
        <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
      </circle>
      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
        <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite" />
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
      </circle>
    </svg>
  </div>
</template>

<script>
const MED_CLS = 'med-wait-container'

export default {
  data () {
    return {
      count: 0,
      timer: null
    }
  },

  beforeMount () {
    let container = document.querySelector('.' + MED_CLS)
    if (!container) {
      container = document.createElement('div')
      container.classList.add(MED_CLS)
      document.body.appendChild(container)
    }
    container.appendChild(this.$el)
  },

  computed: {
    showed () {
      return this.count > 0
    }
  },

  methods: {
    restartTimer (sec) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => { this.reset() }, sec)
    },

    show (sec) {
      this.count++
      this.restartTimer(sec || 6000)
    },

    hide () {
      if (this.showed) {
        this.count--
      }
    },

    reset () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.count = 0
    }
  }
}
</script>

<style lang="less">
.med-wait {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  z-index: 999;
  position: absolute;
  background: #fff;
  opacity: 0.5;
}
</style>
