import Vue from 'vue'

import McbBtnClose from './mcb-btn-close.vue'
import McbDetailPopup from './mcb-detail-popup.vue'
import McbDetail from './mcb-detail.vue'
import McbMultipleValues from './mcb-multiple-values.vue'
import McbSearch from './mcb-search.vue'
import McbToolBar from './mcb-tool-bar.vue'

import MedWait from './med-wait'

Vue.component('mcb-btn-close', McbBtnClose)
Vue.component('mcb-detail-popup', McbDetailPopup)
Vue.component('mcb-detail', McbDetail)
Vue.component('mcb-multiple-values', McbMultipleValues)
Vue.component('mcb-search', McbSearch)
Vue.component('mcb-tool-bar', McbToolBar)

Vue.use(MedWait)
