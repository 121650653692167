<template>
  <el-input
    class="mcb-search"
    v-model="mutableValue"
    suffix-icon="el-icon-search"
    @input="debounceInput"
    clearable />
</template>

<script>
import { debounce } from 'debounce'

export default {
  props: {
    value: {
      type: String
    }
  },

  data () {
    return {
      mutableValue: '',
    }
  },

  watch: {
    value: {
      handler: 'initValue',
      immediate: true
    }
  },

  methods: {
    initValue (val) {
      this.mutableValue = val
    },
    
    debounceInput: debounce(function (val) {
      this.$emit('input', val)
    }, 500)
  }
}
</script>

<style lang="less">
@import "../style.less";

.mcb-search {
  max-width: 250px;
}
</style>
