<template>
  <div class="index-login">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
@import "../style.less";

.index-login {
  // background-color: @background-color1;
  // height: 100%;
}
</style>
