<template>
  <div class="mcb-tool-bar">
    <h1>{{text}}</h1>
    <div class="tools">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.mcb-tool-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 6px;

  .tools {
    flex: auto;
    display: flex;
    justify-content: space-between;
    margin-left: 40px;
  }
}
</style>
