<template>
  <div class="mcb-multiple-values">
    <template v-for="(item, index) in value">
      <slot :item="item" :index="index" />
    </template>   
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>
