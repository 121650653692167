<template>
  <div class="mcb-detail">
    <header class="mcb-detail-head">
      <el-button type="text" icon="el-icon-back" @click="$emit('esc')">Назад к списку</el-button>
    </header>

    <div class="mcb-detail-photo">
      <slot name="photo"/>
    </div>

    <div class="mcb-detail-slot">
      <slot />
    </div>

    <div class="mcb-detail-buttons">
      <el-button type="info" @click="showReason=true" :disabled="readOnly">{{failureText}}</el-button>
      <el-button type="primary" @click="$emit('success')" :disabled="readOnly">Подтвердить</el-button>
    </div>
    <div class="fake" />

    <mcb-detail-popup title="Причина отказа" save-name="Отправить" @save="failureHandle" @esc="showReason=false" v-if="showReason">
      <el-input type="textarea" :rows="4" v-model="reason" />
    </mcb-detail-popup>
  </div>
</template>

<script>
export default {
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },

    failureText: {
      type: String,
      default: 'Не распознано'
    }
  },

  data () {
    return {
      showReason: false,
      reason: ''
    }
  },

  methods: {
    failureHandle () {
      const reason = this.reason.trim()
      if (reason) {
        this.showReason = false
        this.$emit('failure', reason)
      }
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.mcb-detail {
  background-color: @white-color;
  position: absolute;
  top: @header-height;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  overflow: hidden;

  display: grid;
  grid-template-areas: 
    "header header"
    "photo slot"
    "photo buttons"
    "photo fake";
  grid-template-rows: 50px minmax(auto, max-content) max-content 1fr;
  grid-template-columns: 1fr @right-panel-width;
  height: 100vx - @header-height;
  
  .mcb-detail-head {
    grid-area: header;
    padding-left: 20px;
    border-bottom: 1px solid @gray1-color;
    display: flex;
    align-items: center;
  }

  .mcb-detail-photo { 
    grid-area: photo;
    background-color: @gray1-color;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .mcb-detail-slot { 
    grid-area: slot;
    overflow-y: auto;
    padding: 20px;
    // background-color: chocolate;
  }

  .mcb-detail-buttons {
    grid-area: buttons;
    display: flex;
    justify-content: flex-end;
    padding: 2px 20px 10px;
  }

  .fake {
    grid-area: fake;
  }

  .mcb-detail-popup .el-textarea {
    width: 500px;
  }
}
</style>
