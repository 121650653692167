<template>
  <button class="mcb-btn-close" @click="$emit('click')">
    <i class="el-dialog__close el-icon el-icon-close" />
  </button>
</template>

<script>
export default {
}
</script>

<style lang="less">
@import "../style.less";

.mcb-btn-close {
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 18px;
  background: 0 0;
}
</style>
