import fecha from 'fecha'

const ISO_FORMAT = 'YYYY-MM-DD'
const ISO_FORMAT_TIME = 'YYYY-MM-DDTHH:mm:ss'
const RU_FORMAT = 'DD MMMM YYYY'
const RU_FORMAT_TIME = 'DD MMMM YYYY HH:mm'

const ROLE_ADMIN = 1
const ROLE_RECOGNIZER = 2
const ROLE_ASSESSOR = 3

const ROLE_ADMIN_NAME = 'Администратор'
const ROLE_RECOGNIZER_NAME = 'Распознаватель'
const ROLE_ASSESSOR_NAME = 'Ассесор'

const roleOptions = [
  {value: ROLE_ADMIN, label: ROLE_ADMIN_NAME},
  {value: ROLE_RECOGNIZER, label: ROLE_RECOGNIZER_NAME},
  {value: ROLE_ASSESSOR, label: ROLE_ASSESSOR_NAME}
]

const roleNames = {
  [ROLE_ADMIN]: ROLE_ADMIN_NAME,
  [ROLE_RECOGNIZER]: ROLE_RECOGNIZER_NAME,
  [ROLE_ASSESSOR]: ROLE_ASSESSOR_NAME
}

const PRESCRIPTION_GET = 0
const PRESCRIPTION_PREPARED = 1
const PRESCRIPTION_RECOGNIZED_FIO = 2
const PRESCRIPTION_RECOGNIZED_PRESCRIPT = 3
const PRESCRIPTION_RECOGNIZED = 4
const PRESCRIPTION_FAILURE = 5
const PRESCRIPTION_CLOSED = 6

const PRESCRIPTION_GET_NAME = 'Получен'
const PRESCRIPTION_PREPARED_NAME = 'Подготовлен'
const PRESCRIPTION_RECOGNIZED_FIO_NAME = 'ФИО распознан'
const PRESCRIPTION_RECOGNIZED_PRESCRIPT_NAME = 'Назначение распознано'
const PRESCRIPTION_RECOGNIZED_NAME = 'Распознан'
const PRESCRIPTION_FAILURE_NAME = 'Отказ'
const PRESCRIPTION_CLOSED_NAME = 'Подтвержден'

const statusOptions = [
  {value: PRESCRIPTION_GET, label: PRESCRIPTION_GET_NAME},
  {value: PRESCRIPTION_PREPARED, label: PRESCRIPTION_PREPARED_NAME},
  {value: PRESCRIPTION_RECOGNIZED_FIO, label: PRESCRIPTION_RECOGNIZED_FIO_NAME},
  {value: PRESCRIPTION_RECOGNIZED_PRESCRIPT, label: PRESCRIPTION_RECOGNIZED_PRESCRIPT_NAME},
  {value: PRESCRIPTION_RECOGNIZED, label: PRESCRIPTION_RECOGNIZED_NAME},
  {value: PRESCRIPTION_FAILURE, label: PRESCRIPTION_FAILURE_NAME},
  {value: PRESCRIPTION_CLOSED, label: PRESCRIPTION_CLOSED_NAME},
]

const statusNames = {
  [PRESCRIPTION_GET]: PRESCRIPTION_GET_NAME,
  [PRESCRIPTION_PREPARED]: PRESCRIPTION_PREPARED_NAME,
  [PRESCRIPTION_RECOGNIZED_FIO]: PRESCRIPTION_RECOGNIZED_FIO_NAME,
  [PRESCRIPTION_RECOGNIZED_PRESCRIPT]: PRESCRIPTION_RECOGNIZED_PRESCRIPT_NAME,
  [PRESCRIPTION_RECOGNIZED]: PRESCRIPTION_RECOGNIZED_NAME,
  [PRESCRIPTION_FAILURE]: PRESCRIPTION_FAILURE_NAME,
  [PRESCRIPTION_CLOSED]: PRESCRIPTION_CLOSED_NAME,
}


function ruDate (dt) {
  return dt ? fecha.format(fecha.parse(dt, ISO_FORMAT), RU_FORMAT) : ''
}

function ruDateTime (dt) {
  return dt ? fecha.format(fecha.parse(dt, ISO_FORMAT_TIME), RU_FORMAT_TIME) : ''
}

export {
  roleOptions, roleNames, ROLE_ADMIN, ROLE_RECOGNIZER, ROLE_ASSESSOR,
  PRESCRIPTION_GET, PRESCRIPTION_PREPARED, PRESCRIPTION_RECOGNIZED_FIO, PRESCRIPTION_RECOGNIZED_PRESCRIPT, PRESCRIPTION_RECOGNIZED, PRESCRIPTION_FAILURE, PRESCRIPTION_CLOSED, statusNames, statusOptions,
  ruDate, ruDateTime
}
