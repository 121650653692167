<template>
  <div class="index">
    <div class="header">
      <img src="@/assets/logo.svg" />

      <div class="header-menu">
        <el-menu
          :router="true"
          :default-active="$route.name"
          mode="horizontal"
          background-color="#d0d4dd"
          text-color="#303133"
          active-text-color="#0080F6">

          <el-menu-item v-if="menuShowed('fios')" index="fios" :route="{ name: 'fios'}">ФИО</el-menu-item>
          <el-menu-item v-if="menuShowed('prescriptions')" index="prescriptions" :route="{ name: 'prescriptions'}">Назначения</el-menu-item>
          <el-menu-item v-if="menuShowed('controls')" index="controls" :route="{ name: 'controls'}">Контроль</el-menu-item>

          <el-submenu index="guide">
            <template slot="title">Справочники</template>
              <el-menu-item index="clinics" :route="{ name: 'clinics'}">Клиники</el-menu-item>
              <el-menu-item index="drugs" :route="{ name: 'drugs'}">Лекарственные средства</el-menu-item>
              <el-menu-item index="departments" :route="{ name: 'departments'}">Специальности</el-menu-item>
              <el-menu-item index="diagnoses" :route="{ name: 'diagnoses'}">Диагнозы</el-menu-item>
          </el-submenu>

          <el-menu-item v-if="menuShowed('statistics')" index="statistics" :route="{ name: 'statistics'}">Статистика</el-menu-item>
          <el-menu-item v-if="menuShowed('managers')" index="managers" :route="{ name: 'managers'}">Пользователи</el-menu-item>
          <el-menu-item v-if="menuShowed('settings')" index="settings" :route="{ name: 'settings'}">Настройки</el-menu-item>
        </el-menu>
      </div>
  
      <div class="header-div"/>
  
      <el-dropdown @command="selectMenu">
        <span class="el-dropdown-link">
          {{user.login}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-s-tools" command="profile">Профиль</el-dropdown-item>
          <el-dropdown-item icon="el-icon-right" command="logout">Logout</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="view">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  store: ['user'],

  // beforeMount () {
    // this.menuShowed('fio')
  // },

  methods: {
    menuShowed (name) {
      const index = this.$router.options.routes.map(item => item.name).indexOf(name)
      if (index > -1) {
        const route = this.$router.options.routes[index]
        return route.meta && (route.meta.roles.indexOf(this.user.role) > -1)
      }
      return false
    },

    selectMenu (val) {
      switch(val) {
        case 'profile':
          this.$router.push({ name: 'profile' })
          break
        case 'logout':
          this.user.logout()
          break
      }
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.index {
  height: 100vh;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  
  // position: absolute;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;

  .header {
    background-color: @gray3-color;
    height: @header-height;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-menu {
      margin-left: 40px;
      height: 100%;

      display: flex;
      align-items: flex-end;
    }

    .header-div {
      flex: auto;
      height: 100%;
    }
  
    .el-dropdown-link {     
      cursor: pointer;
      color: @black-color;
    }
    .el-icon-arrow-down {
      font-size: 16px;
    }    
  }

  .view {
    padding: 20px;
    flex: auto;
    overflow: auto;
  }
}
</style>
